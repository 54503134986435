<template>
  <main class="container categories">
    <section
      v-if="category?.banner_photo"
      :style="{
        background: 'url(' + category?.banner_photo + ')',
      }"
      class="categories_banner"
      style="position: relative"
    >
      <div style="z-index: 2" class="c_banner_text">
        <p v-html="category?.description[localeLang]"> </p>
        <button
          v-show="category?.banner_button_visible"
          class="banner_btn"
          :style="{ background: `${category?.banner_button_color}` }"
          ><a
            :style="{
              color: `${category?.banner_button_text_color}`,
            }"
            target="_blank"
            :href="category?.banner_button_link"
            >{{ category?.banner_button_text[localeLang] }}</a
          >
        </button>
      </div>
      <img
        style="position: absolute"
        :src="category?.banner_photo"
        alt=""
      />
    </section>

    <section class="d-flex">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" sm="3" class="block_left">
            <h4 class="p17">Filter by</h4>
            <aside class="nav-bar">
              <div class="nav_close">
                <span class="icon_close_1"></span>
                <span class="icon_close_2"></span>
              </div>
              <v-expansion-panels
                style="width: 88%; border: 1px solid #e4f1e3"
                flat
                class="mb-1"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-3">
                    Colors
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                      <div
                        v-for="(color, index) in colors"
                        :key="index"
                        @click="filterByColor(color?.id)"
                        style="cursor: pointer"
                      >
                        <v-badge
                          dot
                          inline
                          :color="color.colorCode"
                          class="mr-3"
                          :class="{ bordered: color.title === 'White' }"
                        ></v-badge>
                        <span>{{ color.title }}</span>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                style="width: 88%; border: 1px solid #f2f2f2"
                class="mb-1"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-3">
                    Size
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container class="pa-0 size-filter" fluid>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input
                              class="size_choice mr-3"
                              type="checkbox"
                            /><span>One-size</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>XS</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>S</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>M</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>L</span>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex align-center">
                            <input type="checkbox" class="size_choice mr-3" />
                            <span>XL</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels
                style="width: 88%; border: 1px solid #f2f2f2"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-4 pr-2 py-3">
                    Price
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-range-slider
                      color="#84C897"
                      v-model="value"
                      track-color="'#E8E8E8'"
                      thumb-label="always"
                      hide-details
                    ></v-range-slider>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </aside>
          </v-col>
          <v-col
            sm="9"
            class="block_right"
            :class="{ 'mx-auto': $vuetify.breakpoint.smAndDown }"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex nav__menu"
                :class="[
                  $vuetify.breakpoint.smAndDown
                    ? 'flex-column'
                    : 'justify-space-between',
                ]"
              >
                <!-- <v-treeview
                  dense
                  open-all
                  v-if="retailCategories[0].children?.length"
                  :items="retailCategories"
                  activatable
                  selection-type="independent"
                  return-object
                  item-children="childrens"
                >
                  <template v-slot:label="{ item }">
                    <div
                      v-if="item?.children?.length"
                      @click="
                        setCategories(item.title[localeLang], item, item.id)
                      "
                      class="v-treeview-node__label"
                      >{{ item.title.ru }}</div
                    >
                  </template>
                </v-treeview> -->
                <!-- <ul class="pa-0">
                  <li
                    v-for="category in retailCategories"
                    :key="category.id"
                    class="nav__link-dropdown"
                  >
                    {{ category.title.ru }}
                  </li>
                </ul> -->
                <!-- <div v-if="$vuetify.breakpoint.smAndDown"> -->
                <ul
                  class="filter_header pa-0"
                  :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
                >
                  <li
                    v-for="categoryItem in retailCategories"
                    :key="categoryItem?.id"
                    :class="{
                      active: $route.params.slug === categoryItem.url,
                    }"
                    class="d-flex align-center f_items nav__link-dropdown"
                  >
                    <span
                      class="nav__link"
                      @click.stop="
                        setCategories(
                          categoryItem.title[localeLang],
                          categoryItem,
                          categoryItem.id
                        )
                      "
                      >{{ categoryItem.title.ru }}</span
                    >
                    <CategoryRecursion
                      @select="
                        (item) => {
                          setCategories(item.title[localeLang], item, item.id)
                        }
                      "
                      :array="categoryItem.childrens"
                    />
                  </li>
                </ul>
                <!-- </div> -->

                <div>
                  <div
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="mb-2 d-flex justify-space-between"
                  >
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Colors</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="d-flex flex-column">
                        <div style="padding: 10px;" v-for="(color, index) in colors" :key="index"  @click="filterByColor(color?.id)">
                          <v-badge
                            dot
                            inline
                            :color="color.colorCode"
                            class="mr-3"
                            :class="{ bordered: color.title === 'White' }"
                          ></v-badge>
                          <span>{{ color.title }}</span>
                        </div>
                      </v-card>
                    </v-menu>
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Size</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-container class="pa-0" fluid>
                        <v-row no-gutters>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              one size
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              xs
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              s
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              m
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              l
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="d-flex">
                              <v-checkbox
                                dense
                                class="ma-0"
                                hide-details
                              ></v-checkbox>
                              xl
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-menu>
                    <v-menu bottom offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          style="border: 1px solid #f2f2f2"
                          v-on="on"
                          v-bind="attrs"
                          class="pl-4 pr-2"
                        >
                          <span class="mr-3">Price</span>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <div class="pt-5">
                        <v-range-slider
                          color="#84C897"
                          v-model="value"
                          track-color="'#E8E8E8'"
                          thumb-label="always"
                          hide-details
                          :thumb-size="15"
                        ></v-range-slider>
                      </div>
                    </v-menu>
                  </div>
                  <div class="sort">
                    <span class="sort_by">Sort by:</span>
                    <v-menu bottom offset-y content-class="sort__menu">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :elevation="0"
                          style="border: 1px solid #f2f2f2; height: unset"
                          class="px-0"
                        >
                          <span class="mr-5">{{ sortedBy }}</span>
                          <span class="arrow_bottom"></span>
                        </v-btn>
                      </template>
                      <ul class="filter__dropdown-menu d-block pa-0">
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                        <li
                          ><a class="menu_items" href="#"
                            >Sort by popularity</a
                          ></li
                        >
                      </ul>
                    </v-menu>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div
              class="products"
              :class="{ 'd-flex': $route.query.section !== 'promotions' }"
            >
              <transition name="slide-fade" mode="out-in">
                <v-row :key="$route.query.section" class="list_product mx-n3">
                  <v-col
                    v-for="product in items"
                    :key="product.product.id"
                    cols="6"
                    md="4"
                    class="pa-3"
                  >
                    <ProductCard
                      :product="product.product"
                      :price="product.price"
                      :currency="product.currency"
                      @click.native="
                        () => {
                          $router.push({
                            name: 'Product',
                            params: { id: product.product.slug },
                          })
                          setSelectedProduct(product.product)
                        }
                      "
                      @goToDetails="
                        () => {
                          $router.push({
                            name: 'Product',
                            params: { id: product.slug },
                          })
                          setSelectedProduct(product)
                        }
                      "
                    />
                  </v-col>
                  <nav
                    v-show="items.length"
                    class="mb-16 d-flex justify-content-lg-end"
                    aria-label="Page navigation example"
                  >
                    <ul class="pagination w-25">
                      <li class="page-item">
                        <a
                          class="page-link"
                          href="#"
                          @click.prevent="  
                            fetchProductsPage({
                              id: $route.query.categoryId,
                              url: productPagination.prev_page_url,
                            })
                          "
                          tabindex="-1"
                          >Previous</a
                        >
                      </li>
                      <li class="page-item text-center">
                        <a
                          class="page-link"
                          @click.prevent="
                            fetchProductsPage({
                              id: $route.query.categoryId,
                              url: productPagination.next_page_url,
                            })
                          "
                          href="#"
                          >Next</a
                        >
                      </li>
                    </ul>
                  </nav>
                </v-row>
              </transition>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import CarouselComponent from '@/view/content/store/CarouselComponent'
import ProductCard from '@/view/content/store/ProductCard'
import CategoryRecursion from './CategoryRecursion.vue'
const { mapState: State_Auth } = createNamespacedHelpers('auth')
const { mapActions } = createNamespacedHelpers('storeCart')
const { mapState } = createNamespacedHelpers('storeProducts')
const { mapState: State_products, mapActions: Actions_products } =
  createNamespacedHelpers('products')

const {
  mapState: State_reatailCategories,
  mapActions: Actions_reatailCategories,
  mapMutations: Mutations_retailCategories,
} = createNamespacedHelpers('retailCategories')
export default {
  name: 'Categories',
  components: {
    ProductCard,
    CategoryRecursion,
  },
  data() {
    return {
      min: -50,
      max: 90,
      value: [-20, 70],
      sortedBy: 'Sort by popularity',
      colorFilterBy: '',
      items: [],
      baseUrl: '',
    }
  },
  computed: {
    ...State_reatailCategories([
      'retailCategories',
      'category',
      'defaultCategory',
    ]),
    ...mapState([
      'colors',
      'feltShoes',
      'interiorItems',
      'accessories',
      'recommendedItems',
      'bestSellers',
      'collections',
    ]),
    ...State_products(['products', 'productPagination']),
    ...State_Auth(['localeLang']),
  },
  watch: {
    products(val) {
      this.items = val
    },
  },
  methods: {
    ...mapActions(['toggleSideCart', 'addCartItem', 'setSelectedProduct']),
    ...Actions_products([
      'searchProducts',
      'getAllProducts',
      'productByCatigory',
      'fetchProductsPage',
    ]),
    ...Actions_reatailCategories([
      'getAllRetailCategories',
      'getRetailCategoryBySlug',
    ]),
    ...Mutations_retailCategories([
      'SET_DEFAULT_CATEGORY',
      'CLEAN_SELECTED_CATEGORY',
    ]),
    selectItem(item) {
      this.setSelectedProduct(item)
      this.$router.push({ name: 'Product', params: { id: item.id } })
    },
    addToCart(item) {
      const copy = cloneDeep(item)
      copy.colors = copy.colors[0]
      copy.colors.sizes = [copy.colors.sizes[0].value]
      this.addCartItem(copy)
      this.toggleSideCart(true)
    },
    filterByColor(id) {
      const color = this.colors.find((color) => color.id === id)
      this.colorFilterBy = color.title
    },
    setCategories(title, category, categoryId) {
      const id = this.$route.query.categoryId
      if(id === categoryId) {
        return true
      }else {
        this.CLEAN_SELECTED_CATEGORY()
        this.activeCategory = title
        this.$router.push({
          name: 'Categories',
          params: { url: category.url },
          query: { categoryId },
        })
      }
    },
  },
  async created() {
    await this.getAllRetailCategories()
    const slug = this.$route.path.split('/').pop()
    await this.getRetailCategoryBySlug(slug)
    // this.getAllProducts()
    this.baseUrl = this.$store.state.auth.API_URL
    // const currentCategory = this.$route.query.category
    // if (!currentCategory) {
    //   this.$router.replace({
    //     query: { category: this.retailCategories[0]?.url },
    //   })
    //   this.productByCatigory({ id: this.retailCategories[0]?.id })
    // }
    // const category = this.retailCategories.find(
    //   (item) => item.url === currentCategory
    // )
    const id = this.$route.query.categoryId
    const initialCategory = this.retailCategories?.find(
      (item) => item.id === id
    )
    this.productByCatigory({ id })

    if (initialCategory) {
      this.SET_DEFAULT_CATEGORY(initialCategory)
    }
  },
}
</script>
<style lang="scss">
.categories {
  .v-slider__thumb {
    border-radius: unset;
    width: 3px;
    left: -1.5px;
    &:before {
      content: none;
    }
  }
  .v-slider__thumb-label {
    height: 10px !important;
    width: 10px !important;
    background-color: unset !important;
    color: black;
    border-color: unset !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 8px 8px 8px 16px;
  }
  .bordered {
    .v-badge__badge {
      border: 1px solid;
      border-color: #e1e1e1 !important;
    }
  }
  .sort {
    .v-btn__content {
      font-size: 0.875rem;
      color: #666666;
      font-weight: 500;
      padding: 2px 14px 2px 10px;
      letter-spacing: normal;
    }
  }
}
.sort__menu {
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  display: block;
}
.p_items {
  & > div {
    border: 1px solid #f2f2f2;
    .p_text {
      border-top: 1px solid #f2f2f2;
    }
  }
}
.v-btn {
  text-transform: unset;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset;
}
.v-expansion-panel-header {
  font-size: 0.875rem;
  color: #666666;
  font-weight: 500;
  padding-left: 8%;
  min-height: unset;
}
.filter_header {
  .f_items.active a {
    color: #666666;
    font-weight: 700;
  }
  .f_items {
    cursor: pointer !important;
  }
  .f_items a {
    font-weight: 500;
    height: 100%;
  }
}
.v-menu__content {
  background-color: #fff;
}
.size-filter {
  span {
    font-size: 14px;
    color: #666666;
    font-weight: 500;
  }
}
.sort_by {
  font-size: 0.875rem;
  color: #a5a5a5;
  font-weight: 500;
  padding-right: 10px;
}
main.container.categories {
  padding-top: 0;
  margin-top: 0;
}
main.container.categories {
  margin-top: 72px !important;
}
.p17 {
  padding: 14px 0;
}
</style>
